import React  from 'react';

import { SecondaryBlockButton } from "../button/Button";
import WizardContent from "../containers/WizardContent"
import { TextContent } from '../../theme/DefaultTheme';
import Icon from "../icon/Icon";
import PropTypes from 'prop-types';

const OnceOffRoadBlock = ({icon,color,heading,description,actions}) => {
    
    return(
        <WizardContent>
            <TextContent style={{paddingTop:40,paddingBottom:40}}>
                <Icon icon={icon} color={color} style={{ fontSize: '4em', marginBottom: '1rem' }} />
                <h1>{heading}</h1>
                {description ? <p>{description}</p> : ''}
        
                {actions.length>0 ? <React.Fragment>
                    <h4 style={{marginTop:30}}>Next steps</h4>
                    {actions.map((returnPath,index)=>{
                        return <SecondaryBlockButton key={index} href={returnPath.url}>{returnPath.label}</SecondaryBlockButton>  
                    })}
                </React.Fragment> : ''}
            
            </TextContent>
        </WizardContent>
     
    )
}

OnceOffRoadBlock.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    heading: PropTypes.string.isRequired,
    description: PropTypes.string,
    actions: PropTypes.array
};

OnceOffRoadBlock.defaultProps = {
    icon: 'warning',
    color: 'green',
    actions: []
};


export default OnceOffRoadBlock
