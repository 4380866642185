import React, { useEffect, useState, useMemo, useContext } from 'react';
import styled, {ThemeContext, withTheme} from "styled-components";
import _ from 'lodash';

import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, useHistory } from "react-router-dom";
import {convertTimestampToDate, CountdownTicker} from "../../../components/progress/CountdownTicker";
import { List, Avatar, Spin } from 'antd';
import Icon from "../../../components/icon/Icon";

import { Formik } from 'formik'
import WizardContent from "../../../components/containers/WizardContent"
import {  postUnitSource, fetchUnitData } from '../../../services/PropertyPassService';
import { updateUnitReservationStep } from '../../../services/EventTriggerService'
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import RoadBlock from '../../../components/containers/RoadBlock'
import withReservation from './ReservationProvider'



const StyledAvatar = styled(Avatar)`
        color:${props => props.theme.primaryActionColor};
        background-color:${props => props.theme.primaryActionBgColor};
`;

const ReservationPayment = (props) => {

    const [appContext] = useContext(ActionRequestContext);
    const { arToken, userToken, actionToken, currentAction } = appContext;
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    let paymentGatewayOptions = useMemo(()=>{

        const allGatewayOptions = [
            {
                label: "Credit Card",
                icon: 'credit-card1',
                gateway: "peach-payments",
                description: 'Take the reservation fee off my credit/debit card',
                url: pathToReservationOnceOffRoute(arToken, userToken, actionToken, "card-payment")
            },
            {
                label: "FNB Scan to Pay",
                icon: 'qrcode',
                gateway: "fnb-scan",
                description: 'Use FNBs App to make payment',
                url: pathToReservationOnceOffRoute(arToken, userToken, appContext.actionToken, "scan-to-pay")
            },
            {
                label: "EFT",
                icon: 'bank',
                gateway: "manual-eft",
                description: 'Manually EFT the amount',
                url: pathToReservationOnceOffRoute(arToken, userToken, actionToken, "manual-eft")
            }
        ]

        const enabledOptions = [];
        allGatewayOptions.forEach((item)=>{
            console.log("comparing",item.gateway,currentAction.paymentMethods[0],item.gateway===currentAction.paymentMethods[0])
            if(currentAction.paymentMethods.indexOf(item.gateway)>-1){
                enabledOptions.push(item)
            }
        })
        return enabledOptions;
    },[]);

    useEffect(() =>{    
        if (!(currentAction.termsAccepted && (currentAction.verificationId !== "" || currentAction.verificationId !== undefined))){            
            props.handleInvalidStep();
        }
        postUnitSource(currentAction.unitSource, true).then(response =>{     
            console.log("data", response.data.unitkey)                   
            if (response.data.unitkey){                
                fetchUnitData(response.data.unitkey).then(unitData => {        
                    console.log("unit Data", unitData)            
                    if (unitData.saleStatus === "sold"){
                        props.handleSoldOut();
                    }else{
                        setLoading(false);                    
                    }                                                          
                })
            }                                    
        })
    }, [])

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])


    const selectPaymentOption = (url) => {
        props.updateLastStep("payment", true).then(data =>{
            history.push(url)
        });            
        
    }

    const handleTimesUp = () => {    
        props.setExpiryDate(false);        
    }

    return (
        <OnceOff>
            <RoadBlock blocked={appContext.blocked}>         
                <WizardContent
                    activeStep={4} totalSteps={6} title="Excellent you are verified" info="To finalise the purchase please select the method you would like to pay the reservation fee"
                    afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp}/>:null}
                >
                    {loading?<React.Fragment>Making sure your unit is still available <Spin/></React.Fragment>:
                    <List
                        itemLayout="horizontal"
                        dataSource={paymentGatewayOptions}
                        bordered
                        renderItem={paymentGateway => {
                                return <List.Item key={paymentGateway.gateway} style={{ textAlign: 'left', marginBottom: 5, cursor:"pointer" }} onClick={()=>{                                    
                                    selectPaymentOption(paymentGateway.url);                                    
                                }}>
                                <List.Item.Meta
                                    avatar={<StyledAvatar size={70} shape="square" icon={<Icon icon={paymentGateway.icon} color={appContext.theme.primaryActionColor} style={{ fontSize: 30 }} />} />}
                                    title={<span style={{ fontSize: 20, marginTop: 10, display: 'block' }}>{paymentGateway.label}</span>}
                                    description={paymentGateway.description}
                                />
                            </List.Item>                                                        
                        }}
                    />}
                </WizardContent>
            </RoadBlock>
        </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPayment))