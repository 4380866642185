
import styled from "styled-components";
import { Layout } from 'antd';
import { Form} from 'formik-antd'

const { Content, Footer } = Layout;

const white = "#FFFFFF";
const black = "#000000";
const textColor = "#000000";

const defaultTheme = {

    fontSize:{
        default: "16px"
    },
    colors:{
        white: "#FFFFFF",
        black: "#000000",        
    },

    primaryActionBgColor: "#23C4DA",
    primaryActionColor: "#000000",

    textColor: textColor,

    bodyBgColor: "#072B43",    
    contentBgColor: white,

    // spacing
    defaultBlockMargin: "25px",

    topbar : {
        bg: white,
        text: textColor,
        bottomBorder: '1px solid #DDE1E2'
    },

    placeholder: {
        color: "#C9C9C9",
        bg: "#E6E6E6"
    },

    onceOffPages: {
        maxWidth: "600px"
    },

    form: {
        narrowWidth: "400px"
    },

    wizard: {        
        headerColor:black,
        headerBgColor:"#D0ECED",     
        progressIndicatorBgActiveColor: "rgba(34, 191, 213,1)",
        progressIndicatorBgColor: "rgba(34, 191, 213,0.4)",
    },

    skeleton: {
        padding: "20px"
    }


};

// MAIN BUILDING BLOCKS OF PAGE
export const TextContent = styled.div`
    font-size: 1.2em;
    margin-bottom: ${props => props.theme.defaultBlockMargin};
`;

export const NarrowForm = styled(Form)`
    max-width: ${props => props.theme.form.narrowWidth};
    margin: 0 auto ${props => props.theme.defaultBlockMargin};
    text-align: left;
`;

export const NarrowContent = styled.div`
    max-width: ${props => props.theme.form.narrowWidth};
    margin: 0 auto ${props => props.theme.defaultBlockMargin};
    text-align: left;
`;

export const PageContentWrapper = styled.div`
    padding-top:72px;
    padding-bottom:0;

    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media only screen and (min-width: 970px) {
        padding-top:150px;
        padding-bottom:150px;
    }
`;

export const PageContent = styled.div`
    // padding: 200px;
`;

export const LoadingContainer = styled.div`
    text-align: center;
    background: white;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 50px 50px;
    margin: 20px 0;
`;

export const PageFooter = styled(Footer)`
`;




export default defaultTheme;