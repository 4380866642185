
import React from 'react';
import { Button as AntButton } from 'antd';
import styled from "styled-components";

export const StyledAntButton = styled(AntButton)`
&&&{
    color:${props => props.theme.primaryActionColor};
    background-color:${props => props.theme.primaryActionBgColor};
    border-color:${props => props.theme.primaryActionBgColor};
    font-weight: bold;
    max-width: 400px;
    margin-bottom: 10px;
    // height: 50px;
    // line-height: 50px;
    &[disabled]{
        background-color: #f5f5f5;
        border-color:#d9d9d9;
    }
}
`;

export const StyledSecondaryButton = styled(AntButton)`
&&&{
    max-width: 400px;
    margin-bottom: 10px;
    // height: 50px;
    // line-height: 50px;

}
`;

const Button = (props) => {
    return <StyledAntButton {...props}>{props.content}</StyledAntButton>
};

const PrimaryBlockButton = (props) => {
    return <StyledAntButton type="primary" size="large" block {...props}>{props.children}</StyledAntButton>
};

const SecondaryBlockButton = (props) => {
    return <StyledSecondaryButton  size="large" block {...props}>{props.children}</StyledSecondaryButton>
};

export {
    PrimaryBlockButton ,
    SecondaryBlockButton,
    Button};
