import React, {useState, useEffect} from 'react';
import { Form, Radio, Select, Input, DatePicker } from 'formik-antd'
import { Col, Row } from 'antd';
import {SelectTwoInRow} from './Responder'
const { Option } = Select;

const ResponderPersonalFinance = ({ pageProps, formProps }) => {

    let purchaseInterest = pageProps.pageData['interest'].rentOrBuy;
    if (purchaseInterest === undefined){
        purchaseInterest = "either"
    }
    const banks = [
        "Absa",
        "African Bank",
        "Bidvest Bank",
        "Capitec Bank",
        "Discovery",
        "FNB",
        "FirstRand Bank",
        "Grindrod Bank",
        "Imperial Bank",
        "Investec Bank",
        "Nedbank",
        "Sasfin Bank",
        "Standard Bank",
        "Ubank",
        "TymeBank"
    ];

    return <React.Fragment>
        {(purchaseInterest === 'buy' || purchaseInterest === 'either') ?
            <React.Fragment>
                <Form.Item label="Do you need a mortgage to buy the house you want?" name="isBondRequired">
                    <Radio.Group name="isBondRequired">
                        <Radio.Button value="false">No, can buy for cash</Radio.Button>
                        <Radio.Button value="true">Yes, will need bond</Radio.Button>
                    </Radio.Group>
                </Form.Item >
                <Form.Item label="Who do you bank with ?" name="personalBank">
                    <Select name="personalBank" placeholder="Select bank">
                        {banks.map(bank => (
                            <Option key={bank}>{bank}</Option>
                        ))}
                    </Select>        
                </Form.Item >
                <Form.Item label="Have you been pre-approved for a mortgage?" name="preApprovedStatus">
                    <Radio.Group name="preApprovedStatus">
                        <Radio.Button value="false">No</Radio.Button>
                        <Radio.Button value="true">Yes</Radio.Button>
                    </Radio.Group>
                </Form.Item >
            </React.Fragment>
        :""}
        {formProps.values.preApprovedStatus === 'true' ?
            <React.Fragment>
                <Form.Item label="Who pre-approved you?" name="preApprovedInstitution">
                    <Select name="preApprovedInstitution" placeholder="Select bank">
                        {banks.map(bank => (
                            <Option key={bank}>{bank}</Option>
                        ))}
                    </Select>        
                </Form.Item >
                <Form.Item label="For what amount?" name="preApprovedAmount">
                    <Input type="number" name="preApprovedAmount" addonBefore="R" defaultValue="" />
                </Form.Item >
                <Form.Item label="Date of approval?" name="preApprovedDate">
                    <DatePicker name="preApprovedDate" showTime={false} placeholder="Select a date" />
                </Form.Item >
            </React.Fragment>                    
        :<Form.Item label="Have had a credit check in the last 3 months?" name="creditCheckStatus">
            <Radio.Group name="creditCheckStatus">
                <Radio.Button value="false">No</Radio.Button>
                <Radio.Button value="true">Yes</Radio.Button>
            </Radio.Group>
                {formProps.values.creditCheckStatus === 'true' ?
                    <Form.Item label="Date of credit check?" name="creditCheckDate">
                        <DatePicker name="creditCheckDate" showTime={false} placeholder="Select a date" />
                    </Form.Item >
                :""}
        </Form.Item >}
        
    </React.Fragment >
}

export default ResponderPersonalFinance
