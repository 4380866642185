import React, {useContext, useReducer} from "react";
import Pusher from "pusher-js";
import {ActionRequestProvider} from "./pages/once-off/ActionRequestContext";
import { createContainer } from 'react-tracked';

import OnceOffError from './pages/once-off/OnceOffError';
import ReservationPurchaser from "./pages/once-off/reservation/ReservationPurchaser";
import ReservationUnitConfirm from "./pages/once-off/reservation/ReservationUnitConfirm";

import Demo from "./pages/demo/Demo";
import Preset from "./pages/demo/Preset";
import ReservationPeachPayment from "./pages/once-off/reservation/ReservationPeachPayment";
import ReservationPaymentEft from "./pages/once-off/reservation/ReservationPaymentEft";
import ReservationOtp from "./pages/once-off/reservation/ReservationOtp";
import ReservationPayment from "./pages/once-off/reservation/ReservationPayment";
import ReservationPaymentScanToPay from "./pages/once-off/reservation/ReservationPaymentScanToPay";

import ReservationPaymentComplete from "./pages/once-off/reservation/ReservationPaymentComplete";
import ReservationPaymentProcessing from "./pages/once-off/reservation/ReservationPaymentProcessing";
import OnceOffWelcome from "./pages/once-off/OnceOffWelcome";
import CreditStart from "./pages/once-off/CreditStart";
import CreditOtp from "./pages/once-off/CreditOtp";
import CreditReport from "./pages/once-off/CreditReport";
import CreditIdentity from "./pages/once-off/CreditIdentity";

import Responder from "./pages/once-off/responder/Responder";


// ---  ROUTES
import {Route, Switch, Redirect  } from "react-router-dom";
// import protectedRoutes from './routes/protectedRoutes'



// --- AUTH ---
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import propertypassFirebase from "./auth/propertypassFirebase.config";
import propertyengineFirebase from "./auth/propertyengineFirebase.config";
import CreditSecurity from "./pages/once-off/CreditSecurity.js";


//export const propertyEngineDb = firebase.initializeApp(propertyengineFirebase,"propertyEngine");


export const AuthContext = React.createContext(null);
export const UserContext = React.createContext({user:null});

export const propertyPassFb = firebase.initializeApp(propertypassFirebase,"propertyPass");;

if (process.env.REACT_APP_PROPERTYPASS_ENV == "local"){        
    propertyPassFb.functions().useFunctionsEmulator('http://localhost:5001');
    propertyPassFb.firestore().settings({
        host: 'localhost:9091',
        ssl: false
    });
}


// ---  PUSHER ---
//@Simon I'm leaving it all on dev so long because pp-functions is pointing to dev, just replace with process.env.REACT_APP_PUSHER
const pusher = new Pusher(process.env.REACT_APP_PUSHER, {
    cluster: 'eu'
});

let pingChannel = pusher.subscribe('ping');
pingChannel.bind('pong', function(data) {
    console.log(JSON.stringify(data));
});

// --- USER TRACKER --- 
//https://www.basefactor.com/global-state-with-react
//https://github.com/dai-shi/react-tracked
//React tracked
const useValue = ({ reducer, initialState }) => useReducer(reducer, initialState);
const initialState = {
    pusher:pusher,
    channels: [{"name":"ping", "channel":pingChannel}],
  };

export const { Provider, useTracked } = createContainer(useValue);

//actions for the global 
const reducer = (state, action) => {
  switch (action.type) {
    case 'subscribeToChannel': {
        if(action.channelName === undefined || action.channelBind === undefined|| action.channelBindFunction === undefined){
            throw new Error(`unknown channel name, bind name, or bind function: ${action}`)
        }
        
        //loop through the channels        
        let channelArray = state.channels;
        let activeChannel = channelArray.find(channel => {
            return channel.name === action.channelName
        })
        if (activeChannel){
            console.info("Channel already exists, ignore subscribe request")
            activeChannel.bind(action.channelBind, action.channelBindFunction);
            return { ...state }
        }else{
            let newChannel = pusher.subscribe(action.channelName);
            newChannel.bind(action.channelBind, action.channelBindFunction);
            channelArray.push(newChannel)
            return { ...state, channels:channelArray }
        }     
    }
    case 'setGlobal': {
        let data = {[action.var]:action.value}        
        return { ...state, ...data}
    }
    case 'setProgress': {    
        if (state.userProgress !== action.value){            
            let newState = {...state, userProgress:action.value}         
            return newState
        }else{            
            return state
        }
        
    }
    case 'setStep': {    
        console.log("setting step", action)    
        return {...state, step:action.step, userProgress:action.hasProgressed, progress:action.progress} 
    }
    case 'setState': {
        console.log("setting state", action)    
        //We need to use "actionState" because state is already in use        
        let newState = {...state, actionState:action.state, userProgress:action.hasProgressed}         
        return newState
    }
    default: throw new Error(`unknown action type: ${action.type}`);
  }
};


export const useSession = () => {
    const { user } = useContext(UserContext);
    return user
};

export const useAuth = () => {
    const [state, setState] = React.useState(() => {
        const user = propertyPassFb.auth().currentUser;
        return { initializing: !user, user, }
    });

    function onChange(user) {
        setState({ initializing: false, user })
    }

    React.useEffect(() => {
        // listen for auth state changes
        const unsubscribe = propertyPassFb.auth().onAuthStateChanged(onChange);
        // unsubscribe to the listener when unmounting
        return () => unsubscribe()
    }, [])

    return state
};




function MainRouter(props) {    
    const { user } = useAuth();
        return (
            <Provider reducer={reducer} initialState={initialState}>
                <UserContext.Provider value={{ user }}>            
                    <Switch>

                        <Route path="/once-off/action/:arToken/:userToken" exact={true} >   
                            <ActionRequestProvider><OnceOffWelcome/></ActionRequestProvider>
                        </Route>
                        {/* CREDIT CHECKING */}
                        <Route path="/once-off/action/:arToken/:userToken/credit-check/start"  exact={true}>
                            <ActionRequestProvider><CreditStart/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/credit-check/otp"  exact={true}>
                            <ActionRequestProvider><CreditOtp/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/credit-check/identity"  exact={true}>
                            <ActionRequestProvider><CreditIdentity/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/credit-check/security"  exact={true}>
                            <ActionRequestProvider><CreditSecurity/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/credit-check/credit-report"  exact={true}>
                            <ActionRequestProvider><CreditReport/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/credit-check/error/:reason" exact={true}>
                            <ActionRequestProvider><OnceOffError /></ActionRequestProvider>
                        </Route>

                        {/* RESPONDER */}
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/responder/:option"  exact={true}>
                            <ActionRequestProvider><Responder/></ActionRequestProvider>
                        </Route>


                        {/* RESERVATION */}
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/unit-confirm"  exact={true}>
                            <ActionRequestProvider><ReservationUnitConfirm/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/purchaser"  exact={true}>
                            <ActionRequestProvider><ReservationPurchaser/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/otp"  exact={true}>
                            <ActionRequestProvider><ReservationOtp/></ActionRequestProvider>
                        </Route>                    
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/payment"  exact={true}>
                            <ActionRequestProvider><ReservationPayment/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/card-payment"  exact={true}>
                            <ActionRequestProvider><ReservationPeachPayment/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/manual-eft"  exact={true}>
                            <ActionRequestProvider><ReservationPaymentEft/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/scan-to-pay"  exact={true}>
                            <ActionRequestProvider><ReservationPaymentScanToPay/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/payment-processing/:transactionId"  exact={true}>
                            <ActionRequestProvider><ReservationPaymentProcessing/></ActionRequestProvider>
                        </Route>
                        <Route path="/once-off/action/:arToken/:userToken/:actionToken/reservation/complete"  exact={true}>
                            <ActionRequestProvider><ReservationPaymentComplete/></ActionRequestProvider>
                        </Route>
                        <Route path="/info" exact={true}>
                            <div>
                                <h2>Current Environment</h2>
                                <p>Env: {process.env.REACT_APP_PROPERTYPASS_ENV}</p>
                                <p>Server: {process.env.REACT_APP_SERVER_ROOT}</p>
                                <p>Test</p>
                            </div>
                        </Route>
                        <Route path="/demo" exact={true}>
                            <Demo/>
                        </Route>
                        <Route path="/preset" exact={true}>
                            <Preset/>
                        </Route>
                        <Route render={() => <Redirect to="/dashboard"/>}/>
                    </Switch>
                </UserContext.Provider>         
            </Provider>           
        );

    
}

export default MainRouter;