import React, {useEffect,useMemo,useContext} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

import _ from 'lodash';
import { Carousel, Alert } from 'antd';

import Icon from "../../components/icon/Icon";

import { ActionRequestContext } from "../../pages/once-off/ActionRequestContext";
import PropertyAttr from "./PropertyAttr";


const PlanSelectorContainer = styled.div`
    h2 {        
        font-weight: bold;
        color: ${props => props.theme.primaryActionBgColor};
        margin-bottom:0px;
    }
    padding: 10px 40px 20px;
    text-align: left;
    background: whitesmoke;
`;

const PlanCarousel = styled(Carousel)`
    width: 100%;
    margin: 0 auto;

    @media only screen and (min-width: 350px) {
        max-width: 280px;      
    }
    @media only screen and (min-width: 450px) {
        max-width: 400px;      
    }
    @media only screen and (min-width: 550px) {
        max-width: 450px;      
    }
    @media only screen and (min-width: 600px) {
        max-width: 100%;      
    }
`;


const PlanText = styled.div`
    h4{
        font-size: 1.2em;
    /* color: ${props => props.theme.primaryActionBgColor}; */
        font-weight:bold;
        border-bottom: 1px solid  ${props => props.theme.primaryActionBgColor};
        padding-bottom: 5px;
        margin-bottom: 20px;
    }
    p{

    }

`;

const PlanInformation = styled.div`
    max-width:100%;
`;

const UnitDescriptors = styled.div`
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid #f0f0f0;
    & > div{
        width: 130px;
        vertical-align: top;
        text-align:center;
    }

`;


const PlanSelector = ({ plans, selectedPlan, onChange, plotSize, unitLabel, unitType, planRequired }) => {


    const [appContext] = useContext(ActionRequestContext);

  

    const arrowStyle = {
        display: 'block',
        top: -14,
        height: 77,
        // background: appContext.theme.primaryActionBgColor,
        color: appContext.theme.primaryActionColor,
        textAlign: 'center',
        width: 32,
    }




    const CarouselPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, ...arrowStyle, left: -40 }}
                onClick={onClick}
            ><Icon icon="chevron-left" color={appContext.theme.primaryActionColor} style={{ fontSize: 20, marginTop: 27, marginRight: 2, fontWeight: 'bold', lineHeight: "20px", verticalAlign: "middle" }} />
            </div>
        );
    }


    const CarouselNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, ...arrowStyle, right: -40 }}
                onClick={onClick}
            >
                <Icon icon="chevron-right" color={appContext.theme.primaryActionColor} style={{ fontSize: 20, marginTop: 27, marginLeft: 2, fontWeight: 'bold', lineHeight: "20px", verticalAlign: "middle" }} />
            </div>
        );
    }


    const speed = 500

    const allowPlanSelection = planRequired === false || (plans && plans.length > 1) 
    let initialSlide = allowPlanSelection ? 0 : 1; // if we are allowed to select a plan then show instructions first, else select the only plan available

    let planContentArray = []        

    const planSelected = (index) => {
        const selectedPlan = planContentArray[index]
        if(selectedPlan){
            onChange({id:selectedPlan.id,label:selectedPlan.label})            
        }else{
            onChange({id:null,label:null})            
        }
    }


    if (plans && selectedPlan != null) {
        //look index     
        let planIndex = plans.findIndex(plan => plan.id == selectedPlan.id);        
        let indexIncrement = (planRequired)?1:2;        
        //Plus 2 is for no option and plot only.
        initialSlide = (planIndex > -1)?(planIndex+indexIncrement):initialSlide;
    }
    const planCarouselSettings = {
        dots: false,
        arrows: allowPlanSelection, // turn off arrows if no plan selection allowed
        infinite: true,
        speed: speed,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: allowPlanSelection, // turn off swipe if no plan selection allowed
        initialSlide: initialSlide,
        adaptiveHeight: true,
        focusOnSelect: true,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        beforeChange: (current, next) => {
            // This hack is required because afterChange doesn't work
            setTimeout(planSelected(next), speed)
        }
    };

    useEffect(()=>{                
        planSelected(initialSlide)
      
    },[])
    
    let planInformation = useMemo(()=>{
            let tempInfo = ""
            if (plans) {
                console.log("we have plans...", plans)
                if (plans.length === 0) {  // either plot or there is no associated plan
                    tempInfo = ""
                }
                else if (plans.length > 0) { // one or more plans
        
                    // Case where you are allowed to just select plot
                    const selectPlanNotice = <PlanInformation key="instructions">
                        <PlanText>
                            <h4>No option selected</h4>
                        </PlanText>
                        <Alert
                            message="Please select a plan"
                            description={`Unit ${unitLabel} has ${plans.length} plans associated to it, please use the arrows on the left and right to select the plan you would like to purchase `}
                            type="info"
                            showIcon
                        />
                    </PlanInformation>
                    planContentArray.push({id:null,label:'', content:selectPlanNotice})
        
                    const plotOnlyOption = <PlanInformation key="plot-only">
                        <PlanText>
                            <h4>Plot only</h4>
                        </PlanText>
                        <Alert
                            description={`Select this option if you do not want to purchase a particular plan with this plot. If you would like to select a plan, keep pressing the side arrows`}
                            type="success"
                        />
                    </PlanInformation>
        
                    if (planRequired !== true) {
                        planContentArray.push({id:'plot-only', label:'Plot only',content:plotOnlyOption})
                    }
        
                    plans.map((plan, index) => {
                        // console.log("plan " + index, plan)
                        const content = <PlanInformation key={index}>
                            <PlanText>
                                <h4>Plan {plan.label}</h4>
                            </PlanText>

                            {plan.floorImages && plan.floorImages.length > 0 ? <img src={plan.floorImages[0].url} style={{ width: "100%", height: 'auto' }} />
                                // Multiple carousel messing with adapative -> https://github.com/akiran/react-slick/issues/1356
                                //  height <PlanMediaCarousel {...planMediaCarouselSettings}>
                                //     {plan.media.map((mediaItem,mediaIndex) =>{
                                //         return <div className='outer' key={mediaIndex}>
        
                                //             </div>
                                //     })}                            
                                //     </PlanMediaCarousel> 
                                : ''
                            }
                            <UnitDescriptors>
                                <PropertyAttr type="type" value={`${unitType}${plans.length>1 ? "+Plan" : ""}`} />
                                {plotSize ? <PropertyAttr label="Plot Size" type="area" value={plotSize} suffix="sqm" /> : ""}
                                <PropertyAttr label="Plan Size" type="area" value={plan.planSize} suffix="sqm" />
        
                                <PropertyAttr type="bedrooms" value={plan.bedrooms} />
                                <PropertyAttr type="bathrooms" value={plan.bathrooms} />
                                <PropertyAttr type="garages" value={plan.garages} />
                                {plan.garages===0 && plan.parking!==0 ? <PropertyAttr label="Parking" type="garages" value={plan.parking} /> :"" }
                            </UnitDescriptors>
                        </PlanInformation>
                        planContentArray.push({id:plan.id, label:plan.label, content:content})
                    })
                    
                    tempInfo = <PlanSelectorContainer>
                        <h2>Selected plan</h2>
                        <PlanCarousel  {...planCarouselSettings} >
                            {planContentArray.map((item,index)=>item.content)}
                        </PlanCarousel>
                    </PlanSelectorContainer>        
                }
            }
        
        
            return tempInfo
       
    },[])


    

 return planInformation
}   


PlanSelector.propTypes = {
    plans: PropTypes.array.isRequired,
    selectedPlan: PropTypes.object,
    onChange: PropTypes.func.isRequired,    
    unitLabel: PropTypes.string.isRequired,    
    planRequired: PropTypes.bool.isRequired,
    unitType: PropTypes.string,
    plotSize: PropTypes.number,
};

PlanSelector.defaultProps = {
    unitType: null,
    plotSize: null,
    selectedPlan: null
}

export default PlanSelector;


