import React, { useState, useContext } from "react";
import {AuthContext, UserContext} from "../../MainRouter";
import firebase from "firebase/app";
import 'firebase/auth';
import { withRouter } from 'react-router-dom';
import {Button, Form, Row, Col, Input} from "antd";

const Join = ({history}) => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setErrors] = useState("");

    const Auth = useContext(AuthContext);
    const User = useContext(UserContext);
    const handleForm = e => {
        e.preventDefault();

        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                firebase
                    .auth()
                    .createUserWithEmailAndPassword(email, password)
                    .then(res => {
                        console.log(res)
                        // history.push('/dashboard');
                        if (res.user){
                            Auth.setLoggedIn(true);
                        }
                    })
                    .catch(e => {
                        setErrors(e.message);
                    });
            })

    };

    const handleGoogleLogin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then(result => {
                        console.log(result)
                        let user = result.user;
                        if(user){

                            Auth.setLoggedIn(true)
                            User.setUserInfo({uid:user.uid, displayName:user.displayName, photoUrl:user.photoURL})
                        }
                        // history.push('/dashboard')

                    })
                    .catch(e => setErrors(e.message))
            })

    }

    return (
        <Row>
            <Col span={8} offset={8}>

                <h1>Join</h1>
                <Form onSubmit={e => handleForm(e)} className="login-form">
                    <Form.Item>
                        <Input
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            name="email"
                            type="email"
                            placeholder="email"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            onChange={e => setPassword(e.target.value)}
                            name="password"
                            value={password}
                            type="password"
                            placeholder="password"
                         />
                    </Form.Item>
                    <Button type="submit">Login</Button>

                    <Button type="primary" onClick={() => handleGoogleLogin()}>
                        Join With Google
                    </Button>


                    <span>{error}</span>
                </Form>
            </Col>
        </Row>
    );
};

export default withRouter(Join);