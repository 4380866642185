import React, {useState, useEffect} from 'react';

import styled from "styled-components";
import { Form, Select } from 'formik-antd'
import { Divider } from 'antd';
import { SecondaryBlockButton } from '../../../components/button/Button';

const { Option } = Select;

const Document = styled.div`
    margin-bottom: 1em;
    h3{
        margin-bottom: 1em;
    }
    a{
        margin-bottom: 0.5em;
    }
`;

const ResponderInformation = ({formProps, developmentInformationMap}) => {
    const [selectedDevelopmentRef, setSelectedDevelopmentRef] = useState(null);
    const [development, setDevelopment] = useState(null);
    const keys = Object.keys(developmentInformationMap);

    
    const [developmentInfo, setDevelopmentInfo] = useState({});

    const fetchDevelopment = (selectedDevelopmentRef) => {                    
        let documentMap = [];
        developmentInformationMap[selectedDevelopmentRef].links.forEach((item) => {
            if (documentMap[item.category] === undefined){
                documentMap[item.category] = []
            }
            documentMap[item.category].push(item);
            setDevelopmentInfo(documentMap);
        })
        
        return
    }

    const handleDevelopmentSelectChange = (value) => {        
        setDevelopment(developmentInformationMap[value]);
        setSelectedDevelopmentRef(value)
    }

    useEffect(() =>{       
        console.log("bloop")     
        console.log(developmentInformationMap)
        console.log(Object.keys(developmentInformationMap).length)        
        if (formProps && formProps.values.development !== undefined){
            setSelectedDevelopmentRef(formProps.values.development)   
            setDevelopment(developmentInformationMap[formProps.values.development]);
        }else if (keys.length === 1){
            //only 1 development            
            setSelectedDevelopmentRef(keys[0]);
            setDevelopment(developmentInformationMap[keys[0]]);
        }
    },[])

    useEffect(() => {                                    
        if (selectedDevelopmentRef) {
            fetchDevelopment(selectedDevelopmentRef)
        }
    }, [selectedDevelopmentRef]);

    console.log("developmenmt", development)
    let developmentInformationPage = selectedDevelopmentRef ? 
        <div>
            {Object.keys(developmentInfo).map((item, index) => {

                const itemDocs = developmentInfo[item]

                return <Document key={index}>
                    <Divider orientation="center">{item}</Divider>
                    {itemDocs.map((doc, index) => {
                        return <SecondaryBlockButton key={index} href={doc.link} target="_blank">{doc.label}</SecondaryBlockButton>
                    })}
                </Document>

            })}            
        </div> : ''

    return <React.Fragment>
        {keys.length > 1 ? 
            <Form.Item label="Select the development you are interested in?" name="development">
                
                <Select name="development" placeholder="Select a development" onChange={handleDevelopmentSelectChange}>
                    {keys.map((key, index) => {                    
                        let item = developmentInformationMap[key]                    
                        return <Option key={item.key}>{item.label}</Option>
                    })
                    }
                </Select>                
                {developmentInformationPage}                        
            </Form.Item>
        :""}
        {(development && keys.length === 1) ?
            <Form.Item label={"Information regarding "+development.label} name="development">
                {developmentInformationPage}  
            </Form.Item>
        :""}
        <SecondaryBlockButton htmlType="submit" >Not what you are after?</SecondaryBlockButton>
    </React.Fragment >

}


export default ResponderInformation;